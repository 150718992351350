.color {
	font-weight: bold;
	padding: 0 4px;
	background-color: black;
}

.black {
	color: black;
	background-color: white;
}

.blue {
	color: blue;
}

.gold {
	color: gold;
}

.green {
	color: green;
}

.red {
	color: red;
}

.white {
	color: white;
}

.yellow {
	color: yellow;
}
