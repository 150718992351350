.app {
	&.portrait {
		.tattoo-canvas {
			position: fixed;
			top: 0;
			z-index: -1;
		}
	}

	&.landscape {
		.tattoo-canvas {
			border: solid 10px #1d1b1b;
		}
	}
}

.tattoo-canvas {
	position: relative;
	z-index: -1;

	img {
		margin: 0;
		padding: 0;
	}
}
