.mobile-artwork-toggle {
	align-items: center;
	background: #1d1b1b;
	cursor: pointer;
	display: flex;
	font-size: 30px;
	justify-content: center;
	position: fixed;
	width: 100%;
	z-index: -1;
}
