.spot {
	align-items: center;
	border-radius: 50%;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	justify-content: center;
	position: absolute;
	text-decoration: none;

	&:hover {
		background: rgba(0, 0, 255, 0.25);
	}

	.marker {
		background: blue;
		border-radius: 50%;
		border: solid 2px black;
		box-sizing: border-box;
		font-weight: bold;
		height: 50%;
		text-align: center;
		width: 50%;
	}
}

.spot.active {
	&:hover {
		background: rgba(255, 0, 0, 0.25);
	}

	.marker {
		background: red;
	}
}
