@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';
@import '../../styles/responsive.scss';

.image-modal {
	align-items: center;
	background: rgba(0, 0, 0, 0.9);
	display: block;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;

	$buttonSize: 50px;
	.close-icon {
		@include border-radius(50%);
		background: black;
		border: none;
		cursor: pointer;
		position: fixed;
		right: 20px;
		width: $buttonSize;
		height: $buttonSize;
		text-align: center;
		top: 15px;

		i {
			color: $white;
			font-size: 35px;
		}
	}

	.image-modal-inner {
		position: relative;
	}
}

// Image Controls
.image-modal-control-left,
.image-modal-control-right {
	border-radius: 50%;
	background: rgba(0, 0, 0, 0.5);
	cursor: pointer;
	font-size: 30px;
	height: 50px;
	position: fixed;
	text-align: center;
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

.image-modal-control-left {
	left: 10px;
	i {
		margin-left: -5px;
	}
}
.image-modal-control-right {
	right: 10px;
	i {
		margin-right: -5px;
	}
}
