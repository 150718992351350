.image-maximizable-container {
	display: flex;
}

.image-maximizable {
	display: block;
	margin: 0;
	margin-bottom: 20px;
	position: relative;

	.zoom-overlay {
		display: none;
	}

	&:hover {
		.zoom-overlay {
			display: flex;
		}
	}

	img {
		height: auto;
		margin: 0;
	}
}

.zoom-overlay {
	background: rgba(0, 0, 0, 0.75);
	height: 100%;
	width: 100%;
	position: absolute;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	z-index: 1;

	i {
		font-size: 40px;
		color: white;
	}
}
