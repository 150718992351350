ul.procreate-settings-list {
	border-radius: 8px;
	background: #1d1b1b;
	list-style-type: none;
	padding: 10px;
	max-width: 300px;

	li {
		justify-content: space-between;
		align-items: center;
		display: flex;

		p {
			margin: 0;
		}
	}
}
