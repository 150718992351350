.icon-tool {
	align-items: center;
	border-radius: 50%;
	bottom: 10px;
	cursor: pointer;
	display: flex;
	font-size: 16px;
	height: 32px;
	justify-content: center;
	position: absolute;
	width: 32px;
	background: rgba(0, 0, 0, 0.25);
}
