.app {
	&.landscape {
		header {
			top: 0;
		}
	}
}

.page-content {
	left: 0;
	overflow-y: scroll;
	padding: 10px 20px;
	position: absolute;
	right: 0;
}
