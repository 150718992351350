.app {
	&.portrait {
		.info-box {
			width: 100%;
			position: fixed;
		}
	}

	&.landscape {
		.info-box {
			border: solid 10px #1d1b1b;
			position: relative;
		}
	}
}

.info-box {
	z-index: -1;

	ul,
	ol {
		margin: 0;
		margin-bottom: 10px;
		padding-left: 20px;

		li {
			margin-bottom: 5px;
		}
	}

	img {
		display: block;
		margin-bottom: 10px;
		max-width: 100%;
		height: auto;
	}
}
